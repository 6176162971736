export default {
    'nav.menu.index': 'Index',
    'nav.menu.news': 'News',
    'nav.menu.contact': 'Contact US',
    'nav.menu.about': 'About US',

    'footer.title': 'Ready to start?',
    'footer.subtitle': 'Safe and secure transaction',
    'footer.contact': 'Contact us',

    'payment.method.pix.title': 'PIX Native Payment',
    'payment.method.pix.desc': 'Support multiple PIX payments. Payment can be transferred directly. The success rate is as high as 90%.',

    'payment.method.upi.title': 'UPI Native Payment',
    'payment.method.upi.desc': 'Support multiple UPI payments. There is no need to scan the code, and the payment can be transferred directly. The success rate is as high as 90%.',

    'partner.title': 'Cooperative partner',

    'page.index.contactUs': 'Contact Us',
    'page.index.title': 'Dedicated to providing global digital payment solutions',
    'page.index.subtitle': 'Make payments easier',

    'page.index.global.payment.title1': 'Set up and accept global payments',
    'page.index.global.payment.title2': 'Out-of-the-box global payment support',
    'page.index.global.payment.desc': 'Quickly build your sales program and support global payments. Feature-rich and out-of-the-box, it supports over 130 currencies, 30+ languages, and 45+ payment methods. With multiple payment processors and smart payment routing, there is no need to choose payment gateways and regional processors. An automatic account renewal service and an integrated retry engine will help you reduce churn and maintain recurring revenue streams from the start. With Avangate\'s international and secure infrastructure, you don\'t have to worry about dealing with VAT and tax, risk and fraud.',
    'page.index.global.payment.languages': 'Over 30 languages supported',
    'page.index.global.payment.methods': 'Over 45 payment methods',

    'page.index.global.api.title': 'Payment API',
    'page.index.global.api.subtitle': 'Sell in 100+ countries from one point',
    'page.index.global.api.advantage.title': 'Support global payments with lower risk and lower cost',
    'page.index.global.api.advantage.desc': 'Accept mainstream payment methods in multiple currencies simply using the Avangate API, enabling you to reach global markets and sell to over 100 countries. Instantly achieve PCI compliance and secure credit card information storage. And leverage Instant Payment Notification (IPN) and License Change Notification (LCN) APIs to scale business operations with lower cost and risk.',

    'page.index.gateway': 'WHY CHOOSE HKP PAYMENT GATEWAY?',
    'page.index.gateway.integration.title': 'Quick integration',
    'page.index.gateway.integration.desc': 'We provide integrated SDK, professional and technical personnel joint debugging test',
    'page.index.gateway.success.rate.title': 'Success rate',
    'page.index.gateway.success.rate.desc': 'Our innovative payment solutions offer the highest transaction success rates in the market',
    'page.index.gateway.payment.method.title': 'Payment method',
    'page.index.gateway.payment.method.desc': 'Support H5/APP, support wallet, debit card, credit card, UPI, online banking and other dozens of payment methods',
    'page.index.gateway.support.title': '24x7 Support',
    'page.index.gateway.support.desc': 'Need help at any stage? You can contact our support team, 24x7 hours at your service',

    'page.index.customer.payment.title': 'Users can pay using any payment method they prefer',
    'page.index.customer.payment.desc': 'We support: UPI, Netbank, Paytm, E-WALLET, VISA, EMI, wallet and other payment methods for users to choose.',

    'page.index.customer.fund.safety.title': 'Our mission is to ensure the financial security of our clients',
    'page.index.customer.fund.safety.pcidss.title': 'PCI DSS',
    'page.index.customer.fund.safety.pcidss.desc': 'HKP is compliant with Payment Card Industry Data Security Standard (PCI DSS) 3.2.1 Level 1. This is the highest level of evaluation and specifically provides a secure environment for online credit/debit card payments.',
    'page.index.customer.fund.safety.ssl.title': 'Secure Sockets Layer (SSL)',
    'page.index.customer.fund.safety.ssl.desc': 'HKP follows Secure Sockets Layer (SSL). It will encrypt the link between the web server and the browser and ensure that all data passed through the web server to the browser remains private and untouched.',
    'page.index.customer.fund.safety.encryption.title': 'Advanced Encryption Standard',
    'page.index.customer.fund.safety.encryption.desc': 'HKP follows the Advanced Encryption Standard, which means that any data you enter is encrypted first. Therefore, it is impossible for anyone to pass your data.',

    'page.about.segment.title': 'ABOUT US',
    'page.about.title': 'Professional global payment solution provider',
    'page.about.desc1': 'HKP was founded by its founder David in 2014, with the aim of helping global small and medium-sized enterprises expand their overseas business and easily and efficiently establish connections with customers in different regions.',
    'page.about.desc2': 'By providing a compliant, secure, stable, and easy-to-use aggregated payment system, we support the collection, payment, and settlement of orders worldwide, solving cross-border business challenges.',

    'page.contact.consult.title': 'Consult',
    'page.contact.consult.form.title': 'Submit your consult',
    'page.contact.consult.form.desc': 'We will contact you as soon as we receive your inquiry',
    'page.contact.consult.form.name': 'Name',
    'page.contact.consult.form.company': 'Company',
    'page.contact.consult.form.email': 'Email',
    'page.contact.consult.form.phone': 'Phone',
    'page.contact.consult.form.message': 'Message',
    'page.contact.consult.form.submit': 'Submit',

    'page.contact.info.title': 'Contact information',
    'page.contact.info.company.title': 'Contact us directly or visit our office',
    'page.contact.info.company.desc': 'You are welcome to contact us and visit us',
    'page.contact.info.address.country': 'Singapore',
    'page.contact.info.address.detail': '1 Fusionopolis Place, #15-2, Galaxis, Singapore',
    'page.contact.info.phone': 'Phone',
    'page.contact.info.email': 'Email',
    'page.contact.info.telegram': 'Telegram',
    'page.contact.info.whatsapp': 'Whatsapp',
    'page.contact.info.skype': 'Skype',
}