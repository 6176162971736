import { STORAGE_KEY_LANGUAGE, DEFAULT_LANGUAGE } from '@/libs/constants'
import { getFromStorageWithDefault, putToStorage } from '@/libs/storage'

const state = {
    language: getFromStorageWithDefault(STORAGE_KEY_LANGUAGE, DEFAULT_LANGUAGE)
}
const getters = {

}
const mutations = {
    setLanguage(state, language) {
        // 设置缓存
        putToStorage(STORAGE_KEY_LANGUAGE, language);
        // 修改状态
        state.language = language;
    }
}
const actions = {
    switchLanguage(context, language) {
        context.commit("setLanguage", language)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}