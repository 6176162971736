<template>
  <footer class="ui-footer ui-waves subscribe-footer">
    <div class="ui-gradient-purple footer-bg">
      <div class="p-t-50 container">
        <div class="ui-card color-card ui-curve form-card shadow-xl">
          <div class="card-header ui-gradient-blue"><h3 class="heading text-white">{{ $t('footer.title') }}</h3>
            <div class="row">
              <div class="col-md-12"><p class="text-center m-t-0 ft-20-500">{{ $t('footer.subtitle') }}</p></div>
            </div>
          </div>
          <div class="card-body">
            <div class="signup" style="border:0 solid #000">
              <router-link to="/contact" :title="$t('footer.contact')" class="cus_signup" style="margin:0">
                {{ $t('footer.contact') }}
            </router-link>
            </div>
          </div>
        </div>
        <div class="download-section">
          <hr class="paykun_ft_hr">
          <div class="row justify-content-center">
            <div class="col-sm-6" style="width:100%">
              <p align="center" class="mb-0"></p>
              <p align="center" class="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped>

</style>