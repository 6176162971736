
/**
 *
 * @param key
 * @returns {any}
 */
export function getFromStorage(key) {
    return localStorage[key]
}

/**
 *
 * @param key
 * @param defaultValue
 */
export function getFromStorageWithDefault(key, defaultValue) {
    return getFromStorage(key) || defaultValue;
}

/**
 *
 * @param key
 * @param value
 */
export function putToStorage(key, value) {
    localStorage[key] = value
}

/**
 *
 * @param key
 */
export function removeFromStorage(key) {
    delete localStorage[key]
}