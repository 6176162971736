<template>
  <div id="app">
    <HeaderView></HeaderView>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <FooterView></FooterView>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView
  }
}
</script>

<style lang="css">
@import './assets/css/global.css'
</style>
