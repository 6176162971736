import { createStore } from 'vuex'

// modules
import app from './modules/app'
import member from './modules/member'

//
export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    // 2. references
    modules: {
        app,
        member
    }
})