export default {
    'nav.menu.index': 'Índice',
    'nav.menu.news': 'Novidades',
    'nav.menu.contact': 'Contate-nos',
    'nav.menu.about': 'Sobre-nós',

    'footer.title': 'Pronto para começar?',
    'footer.subtitle': 'Transação segura e protegida',
    'footer.contact': 'Contate-nos',

    'payment.method.pix.title': 'Pagamento PIX nativo',
    'payment.method.pix.desc': 'Suporta múltiplos pagamentos PIX. O pagamento pode ser transferido diretamente. A taxa de sucesso chega a 90%.',

    'payment.method.upi.title': 'Pagamento Nativo UPI',
    'payment.method.upi.desc': 'Suporta múltiplos pagamentos UPI. Não é necessário escanear o código, e o pagamento pode ser transferido diretamente. A taxa de sucesso chega a 90%.',

    'partner.title': 'Parceiro cooperativo',

    'page.index.contactUs': 'Contate-nos',
    'page.index.title': 'Dedicado a fornecer soluções globais de pagamento digital.',
    'page.index.subtitle': 'Tornar os pagamentos mais fáceis.',

    'page.index.global.payment.title1': 'Configurar e aceitar pagamentos globais.',
    'page.index.global.payment.title2': 'Suporte global de pagamento pronto para uso.',
    'page.index.global.payment.desc': 'Construa rapidamente seu programa de vendas e aceite pagamentos globais. Rico em recursos e pronto para uso, oferece suporte a mais de 130 moedas, 30+ idiomas e 45+ métodos de pagamento. Com vários processadores de pagamento e roteamento inteligente de pagamentos, não é necessário escolher gateways de pagamento e processadores regionais. Um serviço de renovação automática de contas e um mecanismo de tentativas integrado ajudarão a reduzir a perda de clientes e manter fluxos de receita recorrentes desde o início. Com a infraestrutura internacional e segura da Avangate, você não precisa se preocupar com o tratamento de IVA e impostos, riscos e fraudes.',
    'page.index.global.payment.languages': 'Suporte para mais de 30 idiomas.',
    'page.index.global.payment.methods': 'Mais de 45 métodos de pagamento.',

    'page.index.global.api.title': 'API de Pagamento',
    'page.index.global.api.subtitle': 'Venda em mais de 100 países a partir de um único ponto.',
    'page.index.global.api.advantage.title': 'Suporte a pagamentos globais com menor risco e menor custo.',
    'page.index.global.api.advantage.desc': 'Aceite métodos de pagamento convencionais em várias moedas usando simplesmente a API da Avangate, permitindo que você alcance mercados globais e venda para mais de 100 países. Alcance instantaneamente a conformidade com o PCI e o armazenamento seguro de informações de cartão de crédito. E aproveite as APIs de Notificação Instantânea de Pagamento (IPN) e Notificação de Alteração de Licença (LCN) para expandir operações comerciais com menor custo e risco.',

    'page.index.gateway': 'POR QUE ESCOLHER A PLATAFORMA DE PAGAMENTO HKP?',
    'page.index.gateway.integration.title': 'Integração rápida',
    'page.index.gateway.integration.desc': 'Fornecemos SDK integrado, teste conjunto profissional e técnico com pessoal especializado.',
    'page.index.gateway.success.rate.title': 'Taxa de sucesso',
    'page.index.gateway.success.rate.desc': 'Nossas soluções de pagamento inovadoras oferecem as taxas de sucesso de transação mais altas do mercado.',
    'page.index.gateway.payment.method.title': 'Método de pagamento',
    'page.index.gateway.payment.method.desc': 'Suporte para H5/APLICATIVO, carteira, cartão de débito, cartão de crédito, UPI, banco online e dezenas de outros métodos de pagamento.',
    'page.index.gateway.support.title': '24x7 Suporte',
    'page.index.gateway.support.desc': 'Precisa de ajuda em qualquer estágio? Você pode entrar em contato com nossa equipe de suporte, 24x7, à sua disposição.',

    'page.index.customer.payment.title': 'Os usuários podem pagar usando o método de pagamento de sua preferência.',
    'page.index.customer.payment.desc': 'Nós oferecemos suporte a: UPI, Netbank, Paytm, E-WALLET, VISA, EMI, carteira e outros métodos de pagamento para os usuários escolherem.',

    'page.index.customer.fund.safety.title': 'Nossa missão é garantir a segurança financeira de nossos clientes.',
    'page.index.customer.fund.safety.pcidss.title': 'PCI DSS',
    'page.index.customer.fund.safety.pcidss.desc': 'HKP está em conformidade com o Padrão de Segurança de Dados da Indústria de Cartões de Pagamento (PCI DSS) 3.2.1 Nível 1. Este é o mais alto nível de avaliação e fornece especificamente um ambiente seguro para pagamentos online com cartão de crédito/débito.',
    'page.index.customer.fund.safety.ssl.title': 'Secure Sockets Layer (SSL)',
    'page.index.customer.fund.safety.ssl.desc': 'HKP segue o protocolo Secure Sockets Layer (SSL). Ele criptografará a conexão entre o servidor web e o navegador, garantindo que todos os dados transmitidos pelo servidor web para o navegador permaneçam privados e intocados.',
    'page.index.customer.fund.safety.encryption.title': 'O Padrão de Criptografia Avançada (AES)',
    'page.index.customer.fund.safety.encryption.desc': 'HKP segue o Padrão de Criptografia Avançada (AES), o que significa que qualquer dado que você insira é primeiro criptografado. Portanto, é impossível para qualquer pessoa interceptar seus dados.',

    'page.about.segment.title': 'Sobre-nós',
    'page.about.title': 'Fornecedor profissional de soluções de pagamento globais',
    'page.about.desc1': 'A HKP foi fundada por seu fundador David em 2014, com o objetivo de auxiliar pequenas e médias empresas globais a expandir seus negócios no exterior e estabelecer conexões de maneira fácil e eficiente com clientes em diferentes regiões.',
    'page.about.desc2': 'Ao fornecer um sistema de pagamento agregado que é compatível, seguro, estável e fácil de usar, nós apoiamos a coleta, pagamento e liquidação de pedidos em todo o mundo, resolvendo desafios comerciais transfronteiriços.',

    'page.contact.consult.title': 'Consultar',
    'page.contact.consult.form.title': 'Envie a sua consulta',
    'page.contact.consult.form.desc': 'Entraremos em contato assim que recebermos a sua consulta.',
    'page.contact.consult.form.name': 'Nome',
    'page.contact.consult.form.company': 'Empresa',
    'page.contact.consult.form.email': 'Email',
    'page.contact.consult.form.phone': 'Telefone',
    'page.contact.consult.form.message': 'Mensagem',
    'page.contact.consult.form.submit': 'Enviar',

    'page.contact.info.title': 'Informações de contato',
    'page.contact.info.company.title': 'Entre em contato conosco diretamente ou visite nosso escritório.',
    'page.contact.info.company.desc': 'Você é bem-vindo(a) para entrar em contato conosco e nos visitar.',
    'page.contact.info.address.country': 'Singapura',
    'page.contact.info.address.detail': '1 Fusionopolis Place, #15-2, Galaxis, Singapura',
    'page.contact.info.phone': 'Telefone',
    'page.contact.info.email': 'Email',
    'page.contact.info.telegram': 'Telegrama',
    'page.contact.info.whatsapp': 'WhatsApp',
    'page.contact.info.skype': 'Skype',
}