//import { h, resolveComponent } from 'vue';
import { createRouter, createWebHashHistory } from "vue-router";

/*
import IndexView from '@/views/index'
import NewsView from '@/views/news'
import NewsDetailView from '@/views/news/detail'
import AboutView from '@/views/about'
import ContactView from '@/views/contact'
*/

// 路由信息
const routes = [
    { path:'/', redirect:'/index' },
    { path:'/index', name: 'index', component: () => import('@/views/index') },
    { path:'/information', name: 'newsIndex', component: () => import('@/views/news') },
    { path:'/information/detail', name: 'newsDetail', component: () => import('@/views/news/detail') },
    { path:'/about', name: 'about', component: () => import('@/views/about') },
    { path:'/contact', name: 'contact', component: () => import('@/views/contact') },
]

// 路由器
const router = createRouter({
    history: createWebHashHistory(), // HTML5模式
    routes,
})

export default router