export default {
    'nav.menu.index': '首页',
    'nav.menu.news': '资讯',
    'nav.menu.contact': '联系我们',
    'nav.menu.about': '关于我们',

    'footer.title': '准备好开始了吗？',
    'footer.subtitle': '安全可靠的交易',
    'footer.contact': '联系我们',

    'payment.method.pix.title': 'PIX原生支付',
    'payment.method.pix.desc': '支持多家PIX支付。直接调起支付，成功率高达90%以上。',

    'payment.method.upi.title': 'UPI原生支付',
    'payment.method.upi.desc': '支持多家UPI支付。无需扫码，直接调起支付，棋牌游戏成功率高达90%以上。',

    'partner.title': '合作伙伴',

    'page.index.contactUs': '联系我们',
    'page.index.title': '专注提供全球数字支付解决方案',
    'page.index.subtitle': '让支付更简单',

    'page.index.global.payment.title1': '建立并接受全球支付',
    'page.index.global.payment.title2': '开箱即用的全球支付支持',
    'page.index.global.payment.desc': '快速建立您的销售计划并支持全球支付。功能丰富且开箱即用，支持超过 130 种货币、30 多种语言和 45+ 种支付方式。而有了多支付处理器和智能付款途径，便无需选择支付网关和区域处理器。自动账户更新服务和集成重试引擎会帮助您从一开始便减少客户流失，保持重复性收入流的水平。凭借 Avangate 的国际和安全基础设施，您无需担忧如何处理增值税和税务问题，风险和欺诈也不在话下。',
    'page.index.global.payment.languages': '支持超过 30 种语言',
    'page.index.global.payment.methods': '超过 45 种支付方式',

    'page.index.global.api.title': '支付 API',
    'page.index.global.api.subtitle': '可集中通过一点在 100 多个国家/地区进行销售',
    'page.index.global.api.advantage.title': '以更低的风险和更低成本支持全球支付',
    'page.index.global.api.advantage.desc': '仅需使用 Avangate API 即可接受多种货币的主流支付方式，从而能够打入全球市场，向 100 多个国家/地区进行销售。立即达到 PCI 合规并实现安全的信用卡信息存储。而且还可利用即时付款通知 (IPN) 和许可变更通知 (LCN) API，从而以更低的成本和风险扩大业务运营规模。',

    'page.index.gateway': '为什么选择HKP支付网关？',

    'page.index.gateway.integration.title': '快速集成',
    'page.index.gateway.integration.desc': '我们提供集成好的SDK,专业技术人员联调测试',
    'page.index.gateway.success.rate.title': '成功率',
    'page.index.gateway.success.rate.desc': '我们创新的支付解决方案提供市场上最高的交易成功率',
    'page.index.gateway.payment.method.title': '付款方式',
    'page.index.gateway.payment.method.desc': '支持H5/APP，支持钱包，借记卡，信用卡，UPI，网上银行等几十种支付方式',
    'page.index.gateway.support.title': '24x7服务',
    'page.index.gateway.support.desc': '在任何阶段需要帮助？都可以可以联系我们的支持团队，24x7服务小时为您服务',

    'page.index.customer.payment.title': '用户可以使用自己喜欢的任何支付方式进行支付',
    'page.index.customer.payment.desc': '我们支持：UPI，Netbank，Paytm，E-WALLETA，EMI，钱包等多种支付方式，可供用户选择。',

    'page.index.customer.fund.safety.title': '客户的资金安全，是我们的使命',
    'page.index.customer.fund.safety.pcidss.title': 'PCI DSS',
    'page.index.customer.fund.safety.pcidss.desc': 'HKP 符合支付卡行业数据安全标准 (PCI DSS) 3.2.1 Level 1。这是最高级别的评估标准，专门为在线信用卡/借记卡支付提供安全环境。',
    'page.index.customer.fund.safety.ssl.title': '安全套接字层 (SSL)',
    'page.index.customer.fund.safety.ssl.desc': '遵循安全套接字层 (SSL)。它将加密 WEB 服务器和浏览器之间的链接，并确保所有通过 WEB 服务器传递到浏览器的数据保持私密和不变。',
    'page.index.customer.fund.safety.encryption.title': '高等级加密算法',
    'page.index.customer.fund.safety.encryption.desc': 'HKP 遵循 高级加密标准，这意味着您输入的任何数据都会先加密。 因此，任何人都不可能通过您的数据。',

    'page.about.segment.title': '关于我们',
    'page.about.title': '专业的全球支付解决方案供应商',
    'page.about.desc1': 'HKP于2014年由创始人David成立，旨在帮助全球中小企业拓展海外业务，轻松高效的与不同地区的客户建立联系。',
    'page.about.desc2': '通过提供合规、安全、稳定、易用的聚合支付系统，支持世界各地的收单、付款、结算，解决跨境业务难题。',

    'page.contact.consult.title': '咨询',
    'page.contact.consult.form.title': '提交您的咨询',
    'page.contact.consult.form.desc': '我们收到您的咨询后会第一时间联系您',
    'page.contact.consult.form.name': '名称',
    'page.contact.consult.form.company': '公司',
    'page.contact.consult.form.email': '邮箱',
    'page.contact.consult.form.phone': '手机',
    'page.contact.consult.form.messsage': '信息',
    'page.contact.consult.rm.submit': '信息',

    'page.contact.info.title': '联系信息',
    'page.contact.info.company.title': '直接联系或访问我们的办公室',
    'page.contact.info.company.desc': '欢迎您与我们取得联系并拜访我们',
    'page.contact.info.address.country': '新加坡',
    'page.contact.info.address.detail': '1 Fusionopolis Place, #15-2, Galaxis, Singapore',
    'page.contact.info.phone': '电话',
    'page.contact.info.email': '邮箱',
    'page.contact.info.telegram': 'Telegram',
    'page.contact.info.whatsapp': 'Whatsapp',
    'page.contact.info.skype': 'Skype',
}