import { STORAGE_KEY_LANGUAGE, DEFAULT_LANGUAGE } from '@/libs/constants'
import { getFromStorageWithDefault } from '@/libs/storage'

import { createI18n } from "vue-i18n"

import en_US from './lang/en_US'
import zh_CN from './lang/zh_CN'
import pt from './lang/pt'

const locale = getFromStorageWithDefault(STORAGE_KEY_LANGUAGE, DEFAULT_LANGUAGE)

const messages = {
    en_US: {
        ...en_US
    },
    zh_CN: {
        ...zh_CN
    },
    pt: {
        ...pt
    },
}

const i18n = createI18n({
    // 使用 Composition API 模式，则需要将其设置为false
    legacy: false,
    // 全局注入 $t 函数
    globalInjection: true,
    locale,
    messages,
})

export default i18n