<template>
  <nav class="navbar navbar-dark fixed-top navbar-expand-lg" :class="scrollTop < 100 ? 'transparent' : ''">
    <div class="header-offer-bg hidden-sm hidden-xs">
      <div class="header-offer-bg-main"></div>
    </div>
    <div class="container">
      <router-link to="/" class="navbar-brand ui-variable-logo">
        <img src="logo.png" draggable="false" class="logo-default">
        <img src="logo.png" draggable="false" class="logo-transparent">
      </router-link>
      <button type="button" class="navbar-toggler collapsed" aria-expanded="false" @click.prevent="isShowNav = !isShowNav">
        <span class="toggler-icon"></span>
        <span class="toggler-icon"></span>
        <span class="toggler-icon"></span>
      </button>
      <div id="navbar-toggle-collapse" class="ui-navigation navbar-collapse collapse" :class="isShowNav ? 'show' : ''">
        <ul class="navbar-nav nav">
          <li class="nav-item" @click="isShowNav = false">
            <router-link to="/" class="nav-link" :class="$route.name === 'index' ? 'nuxt-link-active' : ''">{{ $t('nav.menu.index') }}</router-link>
          </li>
          <li class="nav-item" @click="isShowNav = false">
            <router-link to="/information" class="nav-link" :class="$route.name === 'newsIndex' || $route.name === 'newsDetail' ? 'nuxt-link-active' : ''">{{ $t('nav.menu.news') }}</router-link>
          </li>
          <li class="nav-item" @click="isShowNav = false">
            <router-link to="/contact" class="nav-link" :class="$route.name === 'contact' ? 'nuxt-link-active' : ''">{{ $t('nav.menu.contact') }} </router-link>
          </li>
          <li class="nav-item" @click="isShowNav = false">
            <router-link to="/about" class="nav-link" :class="$route.name === 'about' ? 'nuxt-link-active' : ''">{{ $t('nav.menu.about') }} </router-link>
          </li>
          <li class="nav-item b-nav-dropdown dropdown">
            <a href="javascript:void(0)" class="nav-link dropdown-toggle nav-link-custom" @click.prevent="isShowLanguageSwitch = !isShowLanguageSwitch">
              <span v-if="currentLanguage">
                {{ currentLanguage.text }}
              </span>
            </a>
            <ul tabindex="-1" class="dropdown-menu dropdown-menu-right" :class="isShowLanguageSwitch ? 'show' : ''">
              <li :key="language.value" @click.prevent="onSwitchLanguage(language)" v-for="language in languages">
                <span class="dropdown-item" role="menuitem">
                  {{ language.text }}
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import { useStore } from 'vuex'
  import { LANGUAGES } from '@/libs/constants'
  export default {
    name: "HeaderView",
    data() {
      return {
        scrollTop: 0,
        isShowNav: false,
        isShowLanguageSwitch: false,
        languages: LANGUAGES,
        currentLanguage: null
      }
    },
    computed: {
    },
    created() {
      this.store = useStore()
      this.currentLanguage = LANGUAGES.filter((e) => {
        return e.value === this.store.state.app.language
      })[0]
    },
    mounted() {
      window.addEventListener('scroll', () => {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      })
    },
    methods: {
      onSwitchLanguage(language) {
        this.currentLanguage = language
        this.store.dispatch('app/switchLanguage', language.value)
        this.$i18n.locale = language.value
        this.isShowLanguageSwitch = false
        this.isShowNav = false
      }
    }
  }
</script>

<style scoped>
  .nav-link.nuxt-link-active {
    border-bottom: 1px solid #0b2e13;
  }
</style>