/**
 *
 * @type {string}
 */
export const LANGUAGES = [
    {
        text: 'English',
        value: 'en_US'
    },
    {
        text: '简体中文',
        value: 'zh_CN'
    },
    {
        text: 'Português',
        value: 'pt'
    }
]
export const STORAGE_KEY_LANGUAGE = '_language_'
export const DEFAULT_LANGUAGE = 'pt'